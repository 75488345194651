import { LitElement, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { SEARCH_RESULTS_LOADED } from '../lib/events';
import { queryParamName } from '../lib/params';
import { LightDOM } from '@cfed/theming/lit/mixins';

@LightDOM
export class SearchSpelling extends LitElement {
    @property({ attribute: 'data-properties', type: Object })
    accessor properties = {};

    @property({ attribute: 'suggested-text', type: String })
    accessor suggestedText = 'Search instead for';

    @state()
    accessor spellingTerm;

    constructor() {
        super();

        document.body.addEventListener(
            SEARCH_RESULTS_LOADED,
            this._handleResultsLoaded.bind(this)
        );
    }

    _handleResultsLoaded({ detail }) {
        this.spellingTerm = detail?.resultData?.spellingTerm;
    }

    searchUrl(value) {
        const url = new URL(
            this.properties.siteSearchPage,
            window.location.origin
        );

        url.searchParams.set(queryParamName, value?.toLowerCase());

        return url.toString();
    }

    render() {
        if (!this.spellingTerm) return nothing;

        return html`
            <div class="search-spelling-main">
                <span class="search-spelling-suggested-text">
                    ${`${this.suggestedText} `}
                </span>
                <a
                    href=${this.searchUrl(this.spellingTerm)}
                    class="search-spelling-link"
                >
                    ${this.spellingTerm}
                </a>
            </div>
        `;
    }
}
