import { LitElement, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { queryParamName } from '../lib/params';
import { RESULTS_LOADING } from '../lib/events';
import { SearchTaxonomyService } from './search-taxonomy-service';
import { LightDOM } from '@cfed/theming/lit/mixins';

@LightDOM
export class SearchTaxonomy extends LitElement {
    @property({ attribute: 'data-model', type: Object })
    accessor model = {};

    constructor() {
        super();

        document.body.addEventListener(
            RESULTS_LOADING,
            this._handleResultsLoading.bind(this)
        );
    }

    async _handleResultsLoading({ detail }) {
        const query = detail.queryObj[queryParamName];
        const service = new SearchTaxonomyService();
        const result = await service.findTopicTerm(
            query,
            detail.sig,
            this.model.taxonomyId
        );

        this.model = {
            ...this.model,
            ...result,
        };
    }

    render() {
        if (!this.model?.taxonomyTerm) return nothing;

        return this.model.isSynonymFound
            ? html`
                  <p class="taxonomy-search__topic-hero--synonym">
                      ${'Suggested related topic '}
                      <a href=${this.model.topicUrl}>
                          ${this.model.taxonomyTerm}
                      </a>
                  </p>
              `
            : html`
                  <h2>${this.model.taxonomyTerm}</h2>
                  <p>${this.model.taxonomyDescription}</p>
              `;
    }
}
